/* eslint-disable array-callback-return */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Box,
	useDisclosure,
	Center,
	HStack,
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
} from '@chakra-ui/react'
import { useSharedContext } from '../../context/shared.context'
import Card from '../../components/card'
import ViewSwitcher from './ViewSwitcher'
import {
	useLoadGanntChildActivity,
	useUpdateActivityDueDates,
	useUpdateActivityProgress,
	useLoadActivityDetails,
} from '../../hooks/activity.hooks'
import { useSaveBaseline, useLoadBaselineData } from '../../hooks/gantt.hooks'
import { getCurrentDateUTC } from '../../utils/common.util'
import { Gantt_PANEL_VIEW } from '../../pages/ganttChart.page'
import { sharedConst } from '../../utils/action.constant'
import GanttActivityHeader from './GanttActivityHeader'
import { GridLoader } from 'react-spinners'
import {
	GanttComponent,
	Inject,
	Edit,
	Selection,
	Sort,
	ColumnsDirective,
	ColumnDirective,
} from '@syncfusion/ej2-react-gantt'
import DatePicker from '../../components/date-picker'
import GanttForm from './GanttForm'
import './gantt.scss'
import moment from 'moment'
import { filterTypes, DependencyType, viewSwitcher } from './gantt.utils'
import {
	getDateSubtracted,
	getDifferenceInDays,
} from './../../utils/date.utils'
import {
	GA_CATEGORY_GANTT,
	sectionDetailedTrack,
} from '../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const GanttView = ({ setPageView, activity }) => {
	const {
		state: { isRefreshGantt },
		dispatch,
	} = useSharedContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const [tasks, setTasks] = useState([])
	const [ganntActivities, setganntActivities] = useState([])
	const [view, setView] = useState(viewSwitcher.Month)
	const [projectStartDate, setProjectStartDate] = useState()
	const [isChecked, setIsChecked] = useState(true)
	const [selectedActivityId, setselectedActivityId] = useState(null)
	const [selectedActivity, setselectedActivity] = useState({})
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [isLoading, setisLoading] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [openCalendar, setOpenCalendar] = useState(false)
	const [selectedDate, setSelectedDate] = useState('')
	const [disableCalendar, setdisableCalendar] = useState(true)
	const [baselineData, setBaselineData] = useState([])
	const [snapShotTime, setsnapshotTime] = useState('')
	const [selectedFilter, setselectedFilter] = useState(filterTypes(locale)[2])
	const [parentActivityData, setParentActivityData] = useState([])

	const { mutate: getGanntChildActivity } = useLoadGanntChildActivity()
	const { mutate: updateActivityDueDates } = useUpdateActivityDueDates()
	const { mutate: updateActivityProgress } = useUpdateActivityProgress()
	const { mutate: saveProjectBaseline } = useSaveBaseline()
	const { mutate: loadBaselineData } = useLoadBaselineData()
	const { mutate: loadActivityDetails } = useLoadActivityDetails()

	const editSettings = {
		allowAdding: false,
		allowEditing: false,
		allowDeleting: false,
		allowTaskbarEditing: true,
		showDeleteConfirmDialog: false,
		mode: 'Auto',
	}

	useEffect(() => {
		if (selectedFilter.value === 'Planned') {
			setdisableCalendar(false)
			onLoadBaselineData(selectedDate, 'Planned')
		} else if (selectedFilter.value === 'Actual') {
			setdisableCalendar(true)
			toLoadChildActivity()
		} else {
			setdisableCalendar(true)
			onLoadBaselineData(selectedDate, 'All')
		}
	}, [selectedFilter, selectedDate])

	useEffect(() => {
		switch (view) {
			case 'Year':
				setProjectStartDate(
					getDateSubtracted(
						activity?.activity_datetime_start_expected,
						'years',
						3
					)
				)
				break
			case 'Month':
				setProjectStartDate(
					getDateSubtracted(
						activity?.activity_datetime_start_expected,
						'months',
						1
					)
				)
				break
			case 'Week':
				setProjectStartDate(
					getDateSubtracted(
						activity?.activity_datetime_start_expected,
						'week',
						1
					)
				)
				break
			case 'Day':
				setProjectStartDate(
					getDateSubtracted(
						activity?.activity_datetime_start_expected,
						'days',
						1
					)
				)
				break
		}
	}, [view])

	useEffect(() => {
		toLoadChildActivity()
		onLoadBaselineData('', 'All')
	}, [activity, isRefreshGantt])

	const getData = data => {
		let children = []

		return data
			.map(mem => {
				const m = { ...mem } // use spread operator
				if (m.subtasks && m.subtasks.length) {
					children = [...children, ...m.subtasks]
				}
				delete m.subtasks // this will not affect the original array object
				return m
			})
			.concat(children.length ? getData(children) : children)
	}

	const toLoadChildActivity = (flag = '', bsData = []) => {
		getGanntChildActivity(
			{
				...loadActivityParams(
					!!selectedDate && flag === '' ? selectedDate : ''
				),
			},
			{
				onSuccess: async res => {
					let data = res?.data?.response
					setganntActivities(data)
					setTasks(await toLoadGanttData(data, bsData))
					setisLoading(false)
					setSelectedDate(flag === 0 ? '' : selectedDate)
					// setBaselineData([]);
				},
			}
		)
	}

	const loadActivityParams = (date = '') => {
		return {
			parent_activity_id: activity?.activity_id,
			page_start: 0,
			page_limit: 30,
			startFrom: 0,
			sort_flag: 0,
			flag: 6,
			datetime_end: getCurrentDateUTC(),
			datetime_start: !!date ? date : '1970-01-01 00:00:00',
		}
	}

	const onLoadBaselineData = (date, type) => {
		loadBaselineData(
			{
				parent_activity_id: activity?.activity_id,
				page_start: 0,
				page_limit: 30,
				startFrom: 0,
				sort_flag: 0,
				flag: 1,
				datetime_end: getCurrentDateUTC(),
				datetime_start: !!date ? date : '1970-01-01 00: 00: 00',
			},
			{
				onSuccess: async res => {
					let data = res
					if (data.length > 0) {
						setganntActivities(data)
						setsnapshotTime(
							moment(data?.[0].snapshot_datetime).format('MMM Do YYYY')
						)
						if (!!type) {
							setBaselineData(getData(data))
							toLoadChildActivity('', getData(data))
							toLoadPrarentActivity(date)
						} else {
							setTasks(await toLoadGanttData(data))
						}
						setisLoading(false)
						setdisableCalendar(false)
					}
				},
				onError: async res => {
					console.log(res)
				},
			}
		)
	}

	const toLoadPrarentActivity = date => {
		loadBaselineData(
			{
				parent_activity_id: activity?.activity_id,
				page_start: 0,
				page_limit: 30,
				startFrom: 0,
				sort_flag: 0,
				flag: 3,
				datetime_end: getCurrentDateUTC(),
				datetime_start: !!date ? date : '1970-01-01 00: 00: 00',
			},
			{
				onSuccess: async res => {
					let data = res
					setParentActivityData(data)
				},
			}
		)
	}

	const Duration = val => {
		const date1 = new Date(val?.activity_datetime_start_expected || '')
		const date2 = new Date(val?.activity_datetime_end_deferred || '')
		// const diffTime = date2.getTime() - date1.getTime();
		// const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		// return diffDays;
		return getDifferenceInDays(date1, date2)
	}

	const toLoadGanttData = async (data, bsData = []) => {
		let uniqueArray = []
		data?.map(el => {
			let prerequisite_list = []
			let baselineActivity = null
			if (bsData && bsData.length > 0) {
				baselineActivity =
					bsData.length > 0 &&
					bsData.find(item => Number(item.activity_id) === el.activity_id)
			}
			if (
				el.prerequisite_list &&
				el.prerequisite_list.length > 0 &&
				el.child_workflows.length === 0
			) {
				prerequisite_list = el?.prerequisite_list.map(val => {
					if (!!val) {
						let configData = JSON.parse(val?.activity_gantt_config || '{}')
						let gantt_config
						if (configData) {
							gantt_config =
								(configData.offset_type === 'lag' ? '+' : '-') +
								(configData.offset_time ? configData.offset_time + 'days' : '')
						}
						return `${val.activity_id}${
							DependencyType[val.activity_mapping_flag_is_prerequisite]
						}${gantt_config}`
					}
				})
			}
			// console.log(toReturnDaysDiff(new Date(activity?.activity_datetime_end_deferred || ''),selectedDate)/( el.child_completion_percentage - (el.activity_completion_percentage?Number(el.activity_completion_percentage):0)))
			let returnObj = {
				StartDate: new Date(el?.activity_datetime_start_expected || ''),
				EndDate: new Date(el?.activity_datetime_end_deferred || ''),
				TaskName: el.activity_title,
				TaskID: el.activity_id.toString(),
				Progress: el.activity_completion_percentage
					? Number(el.activity_completion_percentage)
					: 0,
				Predecessor: !!prerequisite_list ? prerequisite_list.toString() : null,
				Duration: Duration(el),
				subtasks:
					el?.child_workflows && el?.child_workflows.length > 0
						? toLoadTasks(el, bsData)
						: [],
				taskMode: 'Manual',
				PlannedPercentage:
					selectedFilter.value === 'All' || selectedFilter.value === 'Planned'
						? baselineActivity && baselineActivity?.child_completion_percentage
							? baselineActivity.child_completion_percentage
							: 0
						: '',
				ScheduleVariance:
					el.child_completion_percentage && selectedDate
						? toReturnDaysDiff(
								new Date(activity?.activity_datetime_end_deferred || ''),
								selectedDate
						  ) /
						  (el.child_completion_percentage -
								(el.activity_completion_percentage
									? Number(el.activity_completion_percentage)
									: 0))
						: 0,
				BaselineStartDate:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? baselineActivity.activity_datetime_start_expected
						: '',
				BaselineEndDate:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? baselineActivity.activity_datetime_end_deferred
						: '',
				PlannedDuration:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? toReturnDaysDiff(
								baselineActivity.activity_datetime_start_expected,
								baselineActivity.activity_datetime_end_deferred
						  ) +
						  ' ' +
						  'Days'
						: '',
			}
			uniqueArray.push(returnObj)
		})
		let findParent = parentActivityData.find(
			({ activity_id }) => activity_id === activity.activity_id
		)
		let details = await new Promise((resolve, reject) => {
			loadActivityDetails(
				{
					activity_id: activity.activity_id,
					activity_type_id: activity.activity_type_id,
				},
				{
					onSuccess: async details => {
						resolve(details)
					},
				}
			)
		})
		let finalData = [
			{
				StartDate: new Date(details?.activity_datetime_start_expected || ''),
				EndDate: new Date(details?.activity_datetime_end_deferred || ''),
				TaskName: details.activity_title,
				TaskID: details.activity_id.toString(),
				Progress: Number(details.activity_completion_percentage),
				Duration: Duration(details),
				subtasks: toGetUniqueArray(uniqueArray),
				PlannedPercentage:
					selectedFilter.value === 'All' || selectedFilter.value === 'Planned'
						? findParent?.parent_percentage_completion_calculated || 0
						: '',
				ScheduleVariance:
					findParent?.parent_percentage_completion_calculated && selectedDate
						? toReturnDaysDiff(
								new Date(details?.activity_datetime_end_deferred || ''),
								selectedDate
						  ) /
						  (findParent?.parent_percentage_completion_calculated -
								(details.activity_completion_percentage
									? Number(details.activity_completion_percentage)
									: 0))
						: 0,
				BaselineStartDate:
					!!findParent &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? findParent.activity_datetime_start_expected
						: '',
				BaselineEndDate:
					!!findParent &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? findParent.activity_datetime_end_deferred
						: '',
				PlannedDuration:
					!!findParent &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? toReturnDaysDiff(
								findParent.activity_datetime_start_expected,
								findParent.activity_datetime_end_deferred
						  ) +
						  ' ' +
						  'Days'
						: '',
			},
		]

		// toSetGanttView(
		//   details?.activity_datetime_start_expected,
		//   details?.activity_datetime_end_deferred
		// );
		return finalData
	}

	let toLoadTasks = (data, bsData = []) => {
		let finalData = []
		data.child_workflows.map(val => {
			let prerequisite_list = []
			let baselineActivity = null
			if (bsData && bsData.length > 0) {
				baselineActivity = bsData.find(
					item => Number(item.activity_id) === val.activity_id
				)
			}
			if (val.prerequisite_list && val.prerequisite_list.length > 0) {
				prerequisite_list = val.prerequisite_list.map(val1 => {
					let gantt_config
					if (!!val) {
						let configData = JSON.parse(val1?.activity_gantt_config || '{}')
						if (configData) {
							gantt_config =
								(configData.offset_type === 'lag' ? '+' : '-') +
								(configData.offset_time ? configData.offset_time + 'days' : '')
						}
					}

					return `${val1.activity_id}${
						DependencyType[val1.activity_mapping_flag_is_prerequisite]
					}${gantt_config}`
				})
			}
			finalData.push({
				StartDate: new Date(val?.activity_datetime_start_expected || ''),
				EndDate: new Date(val?.activity_datetime_end_deferred || ''),
				TaskName: val.activity_title,
				TaskID: val.activity_id.toString(),
				Progress: val.activity_completion_percentage
					? Number(val.activity_completion_percentage)
					: 0,
				Predecessor: !!prerequisite_list ? prerequisite_list.toString() : null,
				Duration: Duration(val),
				taskMode: 'Manual',
				PlannedPercentage:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? baselineActivity.child_completion_percentage || 0
						: '',
				ScheduleVariance:
					val.child_completion_percentage && selectedDate
						? toReturnDaysDiff(
								new Date(val?.activity_datetime_end_deferred || ''),
								selectedDate
						  ) /
						  (val.child_completion_percentage -
								(val.activity_completion_percentage
									? Number(val.activity_completion_percentage)
									: 0))
						: 0,
				BaselineStartDate:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? baselineActivity.activity_datetime_start_expected
						: '',
				BaselineEndDate:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? baselineActivity.activity_datetime_end_deferred
						: '',
				PlannedDuration:
					!!baselineActivity &&
					(selectedFilter.value === 'All' || selectedFilter.value === 'Planned')
						? toReturnDaysDiff(
								baselineActivity.activity_datetime_start_expected,
								baselineActivity.activity_datetime_end_deferred
						  ) +
						  ' ' +
						  'Days'
						: '',
			})
		})
		return finalData
	}

	let toGetUniqueArray = data => {
		let uniqueArray = []
		data.map(val => {
			let findData = uniqueArray.find(el => el.TaskID === val.TaskID)
			let findInx = uniqueArray.findIndex(el => el.TaskID === val.TaskID)
			if (!!findData) {
				if (!findData.Predecessor) {
					uniqueArray.splice(findInx, 1, val)
				}
			} else {
				uniqueArray.push(val)
			}
		})
		return uniqueArray
	}

	const toReturnDaysDiff = (start, end) => {
		const date1 = new Date(start)
		const date2 = new Date(end)
		// const diffTime = Math.abs(date2 - date1);
		// const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
		// return diffDays;
		return getDifferenceInDays(date1, date2)
	}

	const onParentDateChange = val => {
		let { editingFields } = val
		if (!toGetChnageActivity(editingFields.taskId)) return
		const {
			activity_id,
			asset_id,
			account_id,
			workforce_id,
			activity_type_id,
			parent_activity_id,
		} = toGetChnageActivity(editingFields.taskId)
		updateActivityDueDates(
			{
				organization_id: 868,
				workflow_activity_id: activity_id,
				due_date: editingFields.endDate,
				start_date: editingFields.startDate,
				project_activity_id: activity.activity_id,
				asset_id,
				account_id,
				workforce_id,
				activity_type_id,
				parent_activity_id,
			},
			{
				onSuccess: async res => {
					onClose()
					toLoadChildActivity()
				},
			}
		)

		updateActivityProgress(
			{
				organization_id: 868,
				activity_id,
				activity_workflow_completion_percentage: editingFields.progress,
				asset_id,
			},
			{
				onSuccess: async res => {
					toLoadChildActivity()
				},
			}
		)
	}

	const toGetChnageActivity = activityId => {
		let activityVal
		if (activity.activity_id === Number(activityId)) {
			activityVal = activity
		} else {
			if (ganntActivities.length > 0) {
				activityVal = toCheckNestedActivity(ganntActivities, activityId)
			} else {
				if (ganntActivities[0].activity_id === Number(activityId)) {
					activityVal = ganntActivities[0]
				} else {
					activityVal = ganntActivities[0]?.child_workflows.find(
						val => val.activity_id === Number(activityId)
					)
				}
			}
		}
		return activityVal
	}

	const toCheckNestedActivity = (activityList, activityId) => {
		let finalActivity
		activityList?.map(val => {
			if (val.activity_id === Number(activityId)) {
				finalActivity = val
			} else {
				if (
					val.child_workflows &&
					val.child_workflows?.length > 0 &&
					!finalActivity
				) {
					finalActivity = toCheckNestedActivity(val.child_workflows, activityId)
				}
			}
		})
		return finalActivity
	}

	const onGanttSelect = activityId => {
		setselectedActivityId(activityId)
		dispatch({
			type: sharedConst.ACTVITY_SELECTED,
			payload: toGetChnageActivity(Number(activityId.TaskID)),
		})
		setselectedActivity(toGetChnageActivity(Number(activityId.TaskID)))
		onOpen()
		// }
	}

	const setProjectBaseline = () => {
		saveProjectBaseline(
			{
				activity_id: activity.activity_id,
			},
			{
				onSuccess: async res => {
					if (res.length > 0) {
						setIsModalOpen(false)
					}
				},
			}
		)
	}

	const onChange = value => {
		// onLoadBaselineData(value, selectedFilter.value)
		toLoadPrarentActivity(value)
		setSelectedDate(value)
		setOpenCalendar(false)
	}

	const filterOptionData = item => {
		setselectedFilter(item)
	}

	const taskFields = {
		id: 'TaskID',
		name: 'TaskName',
		startDate: 'StartDate',
		baselineStartDate: 'BaselineStartDate',
		baselineEndDate: 'BaselineEndDate',
		duration: 'Duration',
		progress: 'Progress',
		child: 'subtasks',
		dependency: 'Predecessor',
		plannedDuration: 'PlannedDuration',
	}

	const timelineSettings = {
		timelineViewMode: view,
		updateTimescaleView: false,
		topTier:
			view === 'Day'
				? {
						unit: 'Day',
						formatter: null,
						count: 1,
						format: 'EEE',
				  }
				: view === 'Week'
				? {
						unit: 'Week',
						formatter: null,
						count: 1,
						format: 'EEE MMM dd',
				  }
				: view === 'Month'
				? {
						unit: 'Month',
						formatter: null,
						count: 1,
						format: 'MMM',
				  }
				: view === 'Year'
				? {
						unit: 'Year',
						formatter: null,
						count: 1,
						format: 'yyyy',
				  }
				: view === 'Quarter'
				? {
						unit: 'Month',
						count: 3,
						formatter: date => {
							var month = date.getMonth()
							if (month >= 0 && month <= 2) {
								return 'Q1'
							} else if (month >= 3 && month <= 5) {
								return 'Q2'
							} else if (month >= 6 && month <= 8) {
								return 'Q3'
							} else {
								return 'Q4'
							}
						},
				  }
				: null,
		bottomTier:
			view === 'Quarter'
				? {
						unit: 'Month',
						format: 'MMM',
				  }
				: {
						unit: 'None',
						format: '',
						count: 1,
						formatter: null,
				  },
		//   view === 'Day'
		//     ? {
		//         unit: 'Hour',
		//         format: 'hh',
		//       }
		//     : view === 'Week'
		//     ? {
		//         unit: 'Day',
		//         format: '',
		//       }
		//     : view === 'Month'
		//     ? {
		//         unit: 'Day',
		//         format: 'dd',
		//       }
		//     : view === 'Year'
		//     ? {
		//         unit: 'Month',
		//         format: 'MMM',
		//       }
		//     : null,
		timelineUnitSize: 90,
		// view === 'Day'
		//   ? 33
		//   : view === 'Month'
		//     ? 30
		//     : view === 'Week'
		//       ? 30
		//       : view === 'Year'
		//         ? 30
		//         : null,
	}
	const dayWorkingTime = [{ from: 0, to: 24 }]

	const queryInfo = task => {
		if (Number(task.data.TaskID) === activity.activity_id) {
			task.taskbarBgColor = '#7f838a'
			task.taskbarBorderColor = '#7f838a'
		} else if (
			Number(task.data.TaskID) !== activity.activity_id &&
			task.taskbarType === 'ParentTask'
		) {
			task.taskbarBgColor = 'rgb(197 200 206)'
			task.taskbarBorderColor = 'rgb(197 200 206)'
		}
	}

	return (
		<Card>
			<GanttActivityHeader
				activity={activity}
				toLoadChildActivity={toLoadChildActivity}
				filterData={filterOptionData}
				filterTypes={filterTypes(locale)}
			/>
			<Box display='flex' justifyContent='space-between'>
				<ViewSwitcher
					onViewModeChange={viewMode => {
						setView(viewMode)
					}}
					onViewListChange={setIsChecked}
					isChecked={isChecked}
					view={view}
				/>
				<HStack>
					<Button
						variant='solid'
						size='md'
						borderRadius='md'
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_GANTT,
								action: 'Gantt View',
								label: 'Open Calendar',
							})
							setOpenCalendar(true)
						}}
						isDisabled={disableCalendar}
						cursor={disableCalendar ? 'not-allowed' : 'pointer'}
					>
						{' '}
						{locale['Schedule']}
					</Button>
					{openCalendar ? (
						<div className='ganttContainerDatePicker'>
							<DatePicker
								dateFormat='dd/MM/yyyy'
								showPopperArrow={true}
								selectedDate={
									!!selectedDate
										? selectedDate === 'null'
											? ''
											: new Date(selectedDate)
										: ''
								}
								onChange={onChange}
								open={openCalendar}
								onClickOutside={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_GANTT,
										action: 'Gantt View',
										label: 'Close Calendar',
									})
									setOpenCalendar(false)
								}}
							/>
						</div>
					) : null}
					<Button
						variant='solid'
						size='md'
						borderRadius='md'
						colorScheme={localStorage.getItem('color')}
						bg={localStorage.getItem('color')}
						onClick={() => {
							sectionDetailedTrack({
								category: GA_CATEGORY_GANTT,
								action: 'Gantt View',
								label: 'Save Baseline',
							})
							setIsModalOpen(true)
						}}
					>
						{locale['Save Baseline']}
					</Button>
				</HStack>
			</Box>

			<Box shadow='md'>
				{tasks?.length ? (
					isLoading ? (
						<Box h='calc(100vh - 180px)' w='full'>
							<Center Center p='1' h={'100%'}>
								<GridLoader size={30} margin={5} color='#64bc78' />
							</Center>
						</Box>
					) : (
						<div className='gantt-component'>
							<GanttComponent
								dataSource={tasks}
								// height="500px"
								taskFields={taskFields}
								editSettings={editSettings}
								labelSettings={{ leftLabel: 'TaskName' }}
								timelineSettings={timelineSettings}
								onTaskbarClick={task => onGanttSelect(task.data.taskData)}
								showColumnMenu={false}
								treeColumnIndex={1}
								highlightWeekends={true}
								allowRowDragAndDrop={true}
								allowSorting={true}
								taskbarEdited={({ editingFields }) =>
									onParentDateChange({ editingFields })
								}
								dayWorkingTime={dayWorkingTime}
								includeWeekend={true}
								queryTaskbarInfo={task => queryInfo(task)}
								enableVirtualization={true}
								projectStartDate={projectStartDate}
								projectEndDate='2050-01-01 00:00:00'
								renderBaseline={selectedFilter.value === 'All' ? true : false}
								baselineColor='orange'
								enablePredecessorValidation={false}
							>
								<Inject services={[Selection, Edit, Sort]} />
								<ColumnsDirective>
									<ColumnDirective
										textAlign='left'
										field='TaskID'
										width='0'
									></ColumnDirective>
									<ColumnDirective
										field='TaskName'
										headerText={locale['Task Name']}
										width='200'
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
									<ColumnDirective
										field='StartDate'
										headerText={locale['Start Date']}
										width='200'
										textAlign='left'
										clipMode='EllipsisWithTooltip'
										type='datetime'
										format='dd/MM/yyyy'
									></ColumnDirective>
									<ColumnDirective
										field='EndDate'
										headerText={locale['End Date']}
										width='200'
										textAlign='left'
										clipMode='EllipsisWithTooltip'
										type='datetime'
										format='dd/MM/yyyy'
									></ColumnDirective>
									<ColumnDirective
										field='Duration'
										headerText={locale['Duration']}
										width='200'
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
									<ColumnDirective
										field='Progress'
										headerText={locale['Actual % Complete']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
									<ColumnDirective
										field='PlannedPercentage'
										headerText={locale['Schedule % Complete']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
									<ColumnDirective
										field='BaselineStartDate'
										headerText={locale['Planned Start Date']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
										type='datetime'
										format='dd/MM/yyyy'
									></ColumnDirective>
									<ColumnDirective
										field='BaselineEndDate'
										headerText={locale['Planned End Date']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
										type='datetime'
										format='dd/MM/yyyy'
									></ColumnDirective>
									<ColumnDirective
										field='PlannedDuration'
										headerText={locale['Planned Duration']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
									<ColumnDirective
										field='ScheduleVariance'
										headerText={locale['Schedule Variance']}
										textAlign='left'
										clipMode='EllipsisWithTooltip'
									></ColumnDirective>
								</ColumnsDirective>
							</GanttComponent>
						</div>
					)
				) : null}
			</Box>

			{selectedActivityId && (
				<GanttForm
					isOpen={isOpen}
					onClose={onClose}
					selectedActivityId={selectedActivityId}
					activity={selectedActivity}
					isUpdateModel={false}
					onParentDateChange={onParentDateChange}
					activityList={ganntActivities}
					parantActivity={activity}
				/>
			)}
			<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{locale['Save Baseline']}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Center>
							{!!snapShotTime
								? ` ${locale['Do you want to replace the existing baseline created on']} ${snapShotTime} ?`
								: locale[
										`Are you sure you want to save the data as baseline ?`
								  ]}
						</Center>
					</ModalBody>
					<ModalFooter>
						<Button
							borderRadius='sm'
							size='sm'
							variant='outline'
							colorScheme={localStorage.getItem('color')}
							mr={3}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_GANTT,
									action: 'Gantt View',
									label: 'Close Baseline Modal',
								})
								setIsModalOpen(false)
							}}
						>
							{locale['Close']}
						</Button>
						<Button
							borderRadius='sm'
							size='sm'
							variant='solid'
							colorScheme={localStorage.getItem('color')}
							bg={localStorage.getItem('color')}
							isLoading={isLoading}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_GANTT,
									action: 'Gantt View',
									label: 'Save Project Baseline',
								})
								setProjectBaseline()
							}}
						>
							{locale['Save']}
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Card>
	)
}

export default GanttView
