import React from 'react'
import { Button, Box, Flex, Text } from '@chakra-ui/react'
import { viewSwitcher } from './gantt.utils'
import {
  GA_CATEGORY_GANTT,
  sectionDetailedTrack,
} from '../repository/repo.utils'
import { useLanguageContext } from '../../context/locale.context'

const ViewSwitcher = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
  view,
}) => {
  const {
    state: { locale },
  } = useLanguageContext()
  return (
    <Flex justifyContent="space-between">
      <Box margin="2">
        {/* <Button
          size="sm"
          className="Button"
          onClick={() => onViewModeChange(ViewMode.QuarterDay)}
          bg={view === ViewMode.QuarterDay ? 'green.300' : ''}
        >
          Quarter of Day
        </Button> */}
        {/* <Button
          size="sm"
          className="Button"
          onClick={() => onViewModeChange(ViewMode.HalfDay)}
          bg={view === ViewMode.HalfDay ? 'green.300' : ''}
        >
          Half of Day
        </Button> */}
        <Button
          size="sm"
          className="Button"
          bg={view === viewSwitcher.Day ? localStorage.getItem('color') : ''}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'View Switcher',
              label: 'Day',
            })
            onViewModeChange(viewSwitcher.Day)
          }}
        >
          {locale['Day']}
        </Button>
        <Button
          size="sm"
          className="Button"
          bg={view === viewSwitcher.Week ? localStorage.getItem('color') : ''}
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'View Switcher',
              label: 'Week',
            })
            onViewModeChange(viewSwitcher.Week)
          }}
        >
          {locale['Week']}
        </Button>
        <Button
          size="sm"
          className="Button"
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'View Switcher',
              label: 'Month',
            })
            onViewModeChange(viewSwitcher.Month)
          }}
          bg={view === viewSwitcher.Month ? localStorage.getItem('color') : ''}
        >
          {locale['Month']}
        </Button>
        <Button
          size="sm"
          className="Button"
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'View Switcher',
              label: 'Quarter',
            })
            onViewModeChange(viewSwitcher.Quarter)
          }}
          bg={view === viewSwitcher.Quarter ? localStorage.getItem('color') : ''}
        >
          {locale['Quarter']}
        </Button>
        <Button
          size="sm"
          className="Button"
          onClick={() => {
            sectionDetailedTrack({
              category: GA_CATEGORY_GANTT,
              action: 'View Switcher',
              label: 'Year',
            })
            onViewModeChange(viewSwitcher.Year)
          }}
          bg={view === viewSwitcher.Year ? localStorage.getItem('color') : ''}
        >
          {locale['Year']}
        </Button>
      </Box>
      {/* <Box
        float="right"
        maxW="sm"
        padding="1"
        margin="3"
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
      >
        <Flex>
          <Flex alignItems="center" marginLeft="1">
            <BsFillCircleFill color={'#f7766d'} />
            <Text marginLeft="1" size="md">
              Project
            </Text>
          </Flex>
          <Flex alignItems="center" marginLeft="1">
            <BsFillCircleFill color={'#6995f5'} />
            <Text marginLeft="1" size="md">
              Sub Project
            </Text>
          </Flex>
          <Flex alignItems="center" marginLeft="1">
            <BsFillCircleFill color={'gray'} />
            <Text marginLeft="1" size="md">
              Task
            </Text>
          </Flex>
        </Flex>
      </Box> */}
    </Flex>
  )
}

export default ViewSwitcher
