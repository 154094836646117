export const viewSwitcher = {
  Day: 'Day',
  Week: 'Week',
  Month: 'Month',
  Quarter: 'Quarter',
  Year: 'Year',
}

export const DependencyType = {
  1: 'FS',
  2: 'SF',
  3: 'FF',
  4: 'SS',
}

export const filterTypes = locale => {
  return [
    {
      label: locale['All'],
      value: 'All',
    },
    {
      label: locale['Planned'],
      value: 'Planned',
    },
    {
      label: locale['Actual'],
      value: 'Actual',
    },
  ]
}
