import React, { useState } from 'react'
import { useLoadActivityListV1 } from '../../../hooks/activity.hooks'
import { AsyncPaginate } from 'react-select-async-paginate'
import { useLanguageContext } from '../../../context/locale.context'

const GanttAutoSelect = ({ handleActivitySelection }) => {
  const [data, setdata] = useState([])
  const { mutate: activityListV1Mutate } = useLoadActivityListV1()
  const [selectValue, setselectValue] = useState('')

  const {
    state: { locale },
  } = useLanguageContext()

  const buildWorkflowsRequestParams = (query = '', page_start = 0) => {
    let params = {
      sort_flag: 1,
      flag: 10,
      queue_id: 0,
      is_active: 0,
      is_due: 0,
      current_datetime: '',
      is_unread: 0,
      is_search: query ? 1 : 0,
      search_string: query,
      status_type_id: 0,
      activity_status_id: 0,
      activity_type_id: 0,
      activity_type_category_id: 11,
      page_start: page_start ? page_start : 0,
      page_limit: 10,
      start_from: 0,
      limit_value: 10,
      tag_id: 0,
      tag_type_id: 0,
    }
    return params
  }

  const loadService = async (e, page_start) => {
    return await new Promise(resolve => {
      activityListV1Mutate(
        {
          ...buildWorkflowsRequestParams(e, page_start),
        },
        {
          onSuccess: async res => {
            resolve(res)
          },
        }
      )
    })
  }

  const loadOptions = async (search, loadedOptions) => {
    let resp = await loadService(search, loadedOptions.length)
    console.log(resp)
    let filtered = resp.list.map(i => {
      return {
        label: i.activity_title,
        value: i.activity_id,
      }
    })
    setdata(filtered)
    return {
      options: filtered,
      hasMore: resp.isMore,
    }
  }

  const onChange = e => {
    setselectValue(e)
    handleActivitySelection(e.value)
  }

  return (
    <AsyncPaginate
      placeholder={locale['Smart search']}
      borderRadius="md"
      size="sm"
      options={data}
      loadOptions={loadOptions}
      onChange={onChange}
      value={selectValue}
      maxMenuHeight={350}
    />
  )
}

export default GanttAutoSelect
