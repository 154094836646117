/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Text,
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import {
  useLoadChildOrderActivity,
  useLoadSelectCategory,
} from '../../hooks/activity.hooks'
import { useGetMappingActivity } from '../../hooks/gantt.hooks'
import { useAddParentActivity } from '../../hooks/activity.hooks'
import { getCurrentDateUTC } from '../../utils/common.util'
import { useSharedContext } from '../../context/shared.context'
import { useLanguageContext } from '../../context/locale.context'
import { AsyncPaginate } from 'react-select-async-paginate'
import { sharedConst } from '../../utils/action.constant'
import {
  GA_CATEGORY_DIALOG_BOX,
  sectionDetailedTrack,
} from '../repository/repo.utils'
const SetParantActivityChd = ({
  isOpen,
  onClose,
  activity,
  viewType,
  activityList,
}) => {
  const [isError, setisError] = useState(false)
  const [orderFlag, setOrderFlag] = useState('')
  const { mutate, isLoading } = useLoadSelectCategory()
  const { mutate: loadAllActivities } = useLoadChildOrderActivity()

  const { mutate: getMapActivity, isLoading: getMapActivityLoading } =
    useGetMappingActivity()
  const [options, setOptions] = useState([])
  const { mutate: mutateAddparentActivity } = useAddParentActivity()

  const [selectValue, setselectValue] = useState('')
  const {
    state: { activity: parentActivity },
    dispatch,
  } = useSharedContext()
  const {
    state: { locale },
  } = useLanguageContext()

  useEffect(() => {
    if (!isOpen) return
    if (viewType !== 'SET_CHILD') {
      setData(activityList)
    }
  }, [isOpen])

  const setData = res => {
    let optionData = []
    res.map(item => {
      let { activity_id, activity_title } = item
      if (!!item.child_workflows && item.child_workflows.length > 0) {
        let childWorkflows = item.child_workflows
        childWorkflows.map(childItem => {
          let { activity_id, activity_title } = childItem
          optionData.push({
            label: activity_title,
            value: activity_id,
          })
        })
      }
      optionData.push({
        label: activity_title,
        value: activity_id,
      })
    })
    setOptions([...optionData])
    return optionData.filter(
      (el, i, a) => a.findIndex(({ value }) => value === el.value) === i
    )
  }

  const setParentAcitivity = () => {
    mutateAddparentActivity(
      {
        activity_id:
          viewType === 'SET_CHILD' ? selectValue.value : activity.activity_id,
        parent_activity_id:
          viewType === 'SET_CHILD' ? activity.activity_id : selectValue.value,
      },
      {
        onSuccess: () => {
          dispatch({
            type: sharedConst.REFRESH_GANNT_VIEW,
          })
          onClose()
        },
      }
    )
  }

  const buildWorkflowsRequestParams = (query = '', page_start = 0) => {
    let params = {
      start_from: page_start,
      limit_value: 10,
      activity_type_category_id: 0,
      is_search: query ? 1 : 0,
      search_string: query,
      is_search_enabled: 1,
    }
    return params
  }

  const loadService = async (e, page_start) => {
    return await new Promise(resolve => {
      getMapActivity(
        {
          ...buildWorkflowsRequestParams(e, page_start),
        },
        {
          onSuccess: async res => {
            resolve(res)
          },
        }
      )
    })
  }

  const loadOptions = async (search, loadedOptions) => {
    let resp = []
    let filtered = []
    if (viewType === 'SET_CHILD') {
      resp = await loadService(search, loadedOptions.length)
      filtered = resp.map(i => {
        if (i.activity_title.toLowerCase().includes(search.toLowerCase())) {
          return {
            label: i.activity_title,
            value: i.activity_id,
          }
        } else {
          return {
            label: i.activity_title,
            value: i.activity_id,
          }
        }
      })
      setOptions(filtered)
    } else {
      filtered = setData(activityList)
    }
    return {
      options: filtered,
      hasMore: filtered.length % 10 === 0 ? true : false,
    }
  }

  const onChange = e => {
    setselectValue(e)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="sm">
        <ModalHeader fontSize="lg" fontWeight="400">
          {viewType === 'SET_CHILD'
            ? locale['Set Child Activity']
            : locale['Set Parent Activity']}
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>
          {!isLoading &&
            (viewType === 'SET_CHILD' ? (
              <AsyncPaginate
                placeholder={locale['Smart search']}
                borderRadius="md"
                size="sm"
                options={options.filter(
                  ({ value }) => value !== activity.activity_id
                )}
                loadOptions={loadOptions}
                onChange={onChange}
                value={selectValue}
                maxMenuHeight={350}
              />
            ) : (
              <FormControl id="selectType">
                <FormLabel>{locale['Select Activity']}</FormLabel>
                <Select
                  size="sm"
                  placeholder={locale['Select...']}
                  value={orderFlag}
                  onChange={e => {
                    setOrderFlag(e.target.value)
                  }}
                >
                  {!!options.length &&
                    options
                      .filter(
                        item =>
                          // item.details.activity_type_category_id ==
                          // activity.activity_type_category_id &&
                          item.label !== activity.activity_title
                      )
                      .map(option => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                </Select>
              </FormControl>
            ))}
          {(isLoading || getMapActivityLoading) && (
            <Progress colorScheme={localStorage.getItem('color')} my={5} size="md" isIndeterminate />
          )}
          {!orderFlag && (
            <Text color="red">{locale['Please select the activity!']}</Text>
          )}
          {isError && (
            <Text color="red">
              {locale['Something went wrong please try again!']}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            borderRadius="sm"
            size="sm"
            variant="outline"
            colorScheme={localStorage.getItem('color')}
            mr={3}
            onClick={e => {
              sectionDetailedTrack({
                category: GA_CATEGORY_DIALOG_BOX,
                action: 'Set Parent Activity',
                label: 'Close',
              })
              onClose(e)
            }}
          >
            {locale['Close']}
          </Button>
          {!isError && (
            <Button
              borderRadius="sm"
              size="sm"
              variant="solid"
              colorScheme={localStorage.getItem('color')}
              bg={localStorage.getItem('color')}
              isLoading={isLoading}
              onClick={() => {
                sectionDetailedTrack({
                  category: GA_CATEGORY_DIALOG_BOX,
                  action: 'Set Parent Activity',
                  label: 'Set',
                })
                setParentAcitivity()
              }}
            >
              {locale['Set']}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default SetParantActivityChd
